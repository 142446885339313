import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["primary", "secondary", "tertiary"];
  static values = {
    kinds: Object,
  };

  connect() {}

  populate() {
    this.secondaryTarget.val = "";
    this.secondaryTarget.innerHTML = "";
    this.secondaryTarget.dataset.tomSelectUrlValue =
      this.secondaryTarget.dataset.tomSelectUrlValue.split("?")[0] +
      `?klass=${this.primaryTarget.value}`;
    if (this.secondaryTarget.tomselect != undefined) {
      this.secondaryTarget.tomselect.clearOptions();
      this.secondaryTarget.tomselect.sync();
    }

    this.tertiaryTarget.val = "";
    this.tertiaryTarget.innerHTML = "";
    if (this.tertiaryTarget.tomselect != undefined) {
      this.tertiaryTarget.tomselect.clearOptions();
      this.tertiaryTarget.tomselect.addOptions(
        this.kindsValue[this.primaryTarget.value].map(
          (x) => new Option(x[0], x[1]),
        ),
      );
      this.tertiaryTarget.tomselect.addOptions(this.kindsValue[this.primaryTarget.value]);
    }
  }
}
