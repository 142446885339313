import { Controller } from "@hotwired/stimulus";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import lightMapPinUrl from "~/images/icons/map_pin.png";
import darkMapPinUrl from "~/images/icons/map_pin_dark.png";
import goldMapPinUrl from "~/images/icons/map_pin_gold.png";

export default class extends Controller {
  // I expect an array full of objects that must have coords value which is an array of two numerical coordinates
  // and optionally a desc value which is text description and then style one of {light, dark, gold} with light being default
  // [
  //  {'coords: [50.7696, 15.0479], 'desc': '<b>Sbor v Liberci</b><br>Puchmajerova 4', 'style': 'light'},
  //  {'coords: [50.0767, 14.4381], 'style': 'gold'},
  //  {'coords: [50.0755, 14.4214]}
  // ]
  // The first item is going to be focused and in the middle of the map
  //
  // Required HTML:
  //
  // %div{"data-controller" => "map",'data-map-pins-value' => [ruby_array with values as described above].to_json}
  //   #map

  static values = {
    pins: Array,
    interactive: Boolean,
    scrollZoom: Boolean
  };

  connect() {
    const SEZNAM_MAPS_API_KEY = "K0A6QAJrHi3KAYXaSf4quJZQFPeHnqKyoTtj9IYzuX8";

    const pins = this.pinsValue;
    const interactive = this.interactiveValue;
    const scrollZoom = this.scrollZoomValue;

    const container = this.element.querySelector('#map')

    // Initialize map
    const map = L.map(container, {scrollWheelZoom: scrollZoom}).setView(pins[0].coords, 16);
    var markers = {};

    // Load CB icons
    var lightCbPin = L.icon({
      iconUrl: lightMapPinUrl,
      iconSize: [38, 56],
      iconAnchor: [19, 56],
      popupAnchor: [0, -54],
    });
    var darkCbPin = L.icon({
      iconUrl: darkMapPinUrl,
      iconSize: [38, 56],
      iconAnchor: [19, 56],
      popupAnchor: [0, -54],
    });
    var goldCbPin = L.icon({
      iconUrl: goldMapPinUrl,
      iconSize: [38, 56],
      iconAnchor: [19, 56],
      popupAnchor: [0, -54],
    });

    // Set map defaults
    L.tileLayer(
      `https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${SEZNAM_MAPS_API_KEY}`,
      {
        minZoom: 0,
        maxZoom: 19,
        attribution:
          '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
      },
    ).addTo(map);

    // Add Seznam logo to the bottom left as per their policy
    const LogoControl = L.Control.extend({
      options: { position: "bottomleft" },
      onAdd: function (map) {
        const container = L.DomUtil.create("div");
        const link = L.DomUtil.create("a", "", container);

        link.setAttribute("href", "https://mapy.cz/");
        link.setAttribute("target", "_blank");
        link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />';
        L.DomEvent.disableClickPropagation(link);

        return container;
      },
    });
    new LogoControl().addTo(map);

    // Add markers on the map for each pin
    let top_left = structuredClone(pins[0].coords);
    let bottom_right = structuredClone(pins[0].coords);
    // pins.push({coords: [50.0814, 14.4273], style: 'dark'}, {coords: [50.0714, 14.4373], style: 'gold'})

    pins.forEach(function (pin) {
      if (!pin) {
        return;
      }

      // Update map bounds if we have multiple pins
      top_left[0] = Math.max(top_left[0], pin.coords[0]);
      top_left[1] = Math.min(top_left[1], pin.coords[1]);
      bottom_right[0] = Math.min(bottom_right[0], pin.coords[0]);
      bottom_right[1] = Math.max(bottom_right[1], pin.coords[1]);

      // Put the marker to the map
      let icon = lightCbPin;
      if (pin.style == "dark") {
        icon = darkCbPin;
      }
      if (pin.style == "gold") {
        icon = goldCbPin;
      }

      const marker = L.marker(pin.coords, { icon: icon }).addTo(map);
      if (interactive) {
        marker.on("click", (e) => {
          var target = document.getElementById(`congregation_${pin.id}`);
          [...document.getElementsByClassName("js-selectable")].forEach((el) =>
            el.classList.remove("selected"),
          );
          target.closest(".js-selectable").classList.add("selected");
          target.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        });
        markers[pin.id] = marker;
      }

      // If the pin had a description bind it to the marker
      if (pin.desc) {
        marker.bindPopup(pin.desc);
      }
    });

    // If we have multiple pins, zoom out to see all of them at once
    if (pins.length > 1) {
      // console.log([top_left, bottom_right]);
      let pad = window.screen.width > 1200 ? 100 : 20;
      map.fitBounds([top_left, bottom_right], { padding: [pad, pad] });
    }

    if (interactive) {
      this.map = map;
      this.markers = markers;
    }
  }

  focusMarker({ params: { id } }) {
    const marker = this.markers[id];
    this.map.flyTo(marker.getLatLng(), 15);
    marker.openPopup();
  }

  focusCurrentLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      // console.log(position.coords.latitude, position.coords.longitude);
      this.map.flyTo(
        [position.coords.latitude, position.coords.longitude],
        14.2,
      );
    });
  }

  disconnect() {
    if (this.map) {
      this.map.remove()
    }
  }
}
